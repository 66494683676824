import React from "react";
import Header from "../../components/global/header/header";
import Hero from "../../components/heros/alternate";
import CallToAction from "../../components/global/cta";
import Features from "../../components/products/features";
import Footer from "../../components/global/footer/footer";
import PageTransition from "gatsby-plugin-page-transitions";
import Equalizer from 'react-equalizer';

// Images
import ctaImage from "../../images/7Z8A0083_Output.jpg";
import ecommerce from "../../images/business-solutions/retail.jpg";
import edues from "../../images/business-solutions/ecommerce.jpg";
import { withIntl } from "../../i18n";

// Icons
import icon1 from "../../images/omni-icons/network.svg";
import icon2 from "../../images/omni-icons/smartcard.svg";
import icon3 from "../../images/omni-icons/touch.svg";
import icon4 from "../../images/omni-icons/ungroup.svg";
import icon5 from "../../images/omni-icons/chart.svg";
import icon6 from "../../images/omni-icons/graph.svg";
import icon7 from "../../images/omni-icons/comment-add.svg";
import icon8 from "../../images/omni-icons/credit-locked.svg";

import { FormattedMessage } from "react-intl";

const features = [
	{
		icon: icon8,
		heading: "it_s_robust",
		content: "it_s_robust_para"
	},
	{
		icon: icon1,
		heading: "a_plan_for_everyone",
		content: "a_plan_for_everyone_para"
	},

	{
		icon: icon2,
		heading: "instant_credit",
		content: "instant_credit_para"
	},

	{
		icon: icon3,
		heading: "donations",
		content: "donations_para"
	},

	{
		icon: icon4,
		heading: "news_events",
		content: "news_events_para"
	},

	{
		icon: icon5,
		heading: "intuitive_design",
		content: "intuitive_design_para"
	},

	{
		icon: icon7,
		heading: "chat_groups",
		content: "chat_groups_para"
	},

	{
		icon: icon6,
		heading: "reports",
		content: "reports_para"
	},
];

class ProductPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contactOpen: false
		};
	}

	toggleContact = () => {
		this.setState({
			contactOpen: !this.state.contactOpen
		});
		document.body.classList.toggle("noscroll");
	};

	getNodes(equalizerComponent, equalizerElement) {
        return [
            this.refs.equalizeThis,
            this.refs.equalizeThat,
        ]
	}
	
	componentDidMount() {
        if(document.body.classList.contains('noscroll')) {
            document.body.classList.remove('noscroll');
        }
    }

	render() {
		var large = "(min-width: 1024px)";

		return (
			<PageTransition
				defaultStyle={{
					transition: "all 1100ms cubic-bezier(0.8, 0, 0.2, 1)",
					position: "relative",
					width: "100%",
					opacity: 0,
					maxWidth: "2000px"
				}}
				transitionStyles={{
					entering: {
						opacity: 1
					},
					entered: {
						opacity: 1
					},
					exiting: {
						opacity: 0
					}
				}}
				transitionTime={1250}
			>
				<Header
					headerStyle="std"
					contactOpen={this.state.contactOpen}
					action={() => this.toggleContact()}
					{...this.props}
				/>

				<Hero
					featImage={ecommerce}
					pageTitleSub="group_collections"
					pageTitleMain="ecommerce"
					pageIntro="Enterprise based solutions handling multiple business channels which allows new sports and data feeds to be added while providing a low latency price generating solution"
				/>

				<section className="omni pad-top bg-white">
					<Equalizer
						className="row expanded collapse"
						byRow={false}
						property="height"
						enabled={() => window.matchMedia(large).matches}
						nodes={this.getNodes.bind(this)}
					>
						<div className="small-12 medium-push-1 medium-10 large-push-2 large-4 columns print-omni__platform">
							<div className="row expanded">
								<div className="small-12 columns">
									<div className="omni-content pad-bot mpad" ref="equalizeThis">
										<div className="omni-content__jacket">
											<em>
												<FormattedMessage id="advanced" />
											</em>

											<h2 className="dark heading">
												<span>
													<FormattedMessage id="platform" />
												</span>
											</h2>

											<p className="dark">
												<FormattedMessage id="ecommerce_solution_para" />{" "}
											</p>

											<p className="dark">
												<FormattedMessage id="our_ecommerce_solution_provides_para" />
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="small-12 large-4 columns no-print">
							<div className="omni-image show-for-large" ref="equalizeThat">
								<img src={edues} alt="edues image" />
							</div>
						</div>
					</Equalizer>
				</section>

				<Features subTitle="fully" mainTitle="featured" features={features} />

				<CallToAction
					image={ctaImage}
					heading="tailor_made_perfect_fit"
					content="lets_get_in_touch"
					buttonLabel="contact_us"
					action={() => this.toggleContact()}
				/>

				<Footer />
			</PageTransition>
		);
	}
}

export default withIntl(ProductPage);




// ./src/pages/solutions/omnichannel.jsx